import React from "react";
import * as styles from "./_DemoForm.module.scss";
import { TypographyV2 } from "@components";
import Input from "@components/InputV2/InputV2";
import Select from "@components/SelectV2/SelectV2";
import { useHubspotForm } from "@helpers/Hooks";
import { SubmitbuttonV2 } from "@components/Button_new/Button";
import GenemodLink from "@components/Link/Link";
import freeEmailDomains from "free-email-domains";

const DEMO_SUBMISSION_ROUTE = `https://api.hsforms.com/submissions/v3/integration/submit/19551518/f0436806-af62-4b53-8bfd-f3f4de1bf949`;

const TEAM_SIZE = ["1 - 7", "8 - 15", "16 - 50", "51 - 100", "100 +"] as const;

export default function DemoForm({ location }: any) {
	const { Option } = Select;
	const { inputProps, handleSubmit, submitStatus, initForm } = useHubspotForm(
		{
			email: location?.state?.email || "",
			firstname: "",
			lastname: "",
			company: "",
			team_size_range: "",
		},
		DEMO_SUBMISSION_ROUTE
	);

	const validateFreeEmail = (value: string): boolean => {
		const emailDomain = value.split("@");
		if (emailDomain[1] && freeEmailDomains.includes(emailDomain[1])) {
			return false;
		}
		return true;
	};

	return (
		<div className={styles.rightContainer}>
				<TypographyV2
							variant="HEADING_5"
							tabletVariant="HEADING_2"
							mobileVariant="HEADING_3"
							className={styles.headerText}
							color={'brand-500'}
						>
							Let’s talk
						</TypographyV2>
			<div className={styles.body}>
				<Input
					className={styles.input}
					inputClassName={styles.input}
					label={"Work email"}
					{...inputProps("email")}
					autoComplete="email"
					labelClassName={styles.inputLabel}
					fullWidth
					emailValidator
					placeholder="email@company.com"
					inputErrorColor="error-400"
					disabled={
						submitStatus === "SUCCESS" ||
						submitStatus === "SUBMITTING"
					}
					validators={[
						{
							validator: (val) => validateFreeEmail(val),
							error: "Please enter a business email",
						},
					]}
				/>

				<div className={styles.inputGrid}>
					<Input
						label={"First name"}
						className={styles.input}
						inputClassName={styles.input}
						{...inputProps("firstname")}
						autoComplete="given-name"
						labelClassName={styles.inputLabel}

						fullWidth
						required
						inputErrorColor="error-400"
						customErrorMessage="Required"
						disabled={
							submitStatus === "SUCCESS" ||
							submitStatus === "SUBMITTING"
						}
					/>
					<Input
						label={"Last name"}
						className={styles.input}
						inputClassName={styles.input}
						{...inputProps("lastname")}
						autoComplete="family-name"
						labelClassName={styles.inputLabel}
						fullWidth
						required
						inputErrorColor="error-400"
						customErrorMessage="Required"
						disabled={
							submitStatus === "SUCCESS" ||
							submitStatus === "SUBMITTING"
						}
					/>
				</div>
				<div className={styles.inputGrid}>
					<Input
						label={"Company"}
						className={styles.input}
						inputClassName={styles.input}
						{...inputProps("company")}
						labelClassName={styles.inputLabel}
						fullWidth
						required
						inputErrorColor="error-400"
						customErrorMessage="Required"
						disabled={
							submitStatus === "SUCCESS" ||
							submitStatus === "SUBMITTING"
						}
					/>
					<Select
						label={"Team size"}
						{...inputProps("team_size_range")}
						fullWidth
						required
						inputErrorColor="error-400"
						labelClassName={styles.inputLabel}

						customErrorMessage="Required"
						disabled={
							submitStatus === "SUCCESS" ||
							submitStatus === "SUBMITTING"
						}
					>
						{TEAM_SIZE.map((opt) => (
							<Option key={opt}>{opt}</Option>
						))}
					</Select>
				</div>
				
				<div className={styles.submitSection}>
					<SubmitbuttonV2
						submitStatus={submitStatus}
						onClick={handleSubmit}
						className={styles.submitButton}
						containerClassName={styles.submitButtonContainer}
					>
						Book a demo
					</SubmitbuttonV2>
					{submitStatus === "NOT_SUBMITTED" && (
						<TypographyV2
							variant="BODY_TEXT_EXTRA_SMALL"
							weight="REGULAR"
							color="text-helper"
							className={styles.privacyText}
						>
							By requesting a demo you agree to Genemod’s{" "}
							<GenemodLink
								link="PRIVACY_POLICY"
								className={styles.privacyLink}
							>
								Privacy Policy
							</GenemodLink>
							, and you consent to receive marketing
							communications from Genemod.
						</TypographyV2>
					)}
				</div>
			</div>
		</div>
	);
}
