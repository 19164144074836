import { TypographyV2 } from "@components/index";
import { PartnersV2 } from "pages/landingpagev2/_components/_Partners/_Partners";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Typography } from "@components";
import * as styles from "./_Hero.module.scss";
import DemoForm from "../_DemoForm/_DemoForm";

export default function Hero() {
	return (
		<div className={styles.container}>
			<div className={styles.hero}>
				<div className={styles.sectionA}>
					{/* left */}
					<div className={styles.leftSection}>
						{/* text contaiers */}
						<div className={styles.heroTextContent}>
							<TypographyV2
								variant="HEADING_1"
								tabletVariant="HEADING_2"
								mobileVariant="HEADING_3"
								className={styles.headerText}
								color={"brand-500"}
							>
								Modern lab management software for scientists
							</TypographyV2>
							<TypographyV2
								variant="BODY_TEXT_EXTRA_LARGE"
								mobileVariant="BODY_TEXT_LARGE"
								weight="REGULAR"
								color={"text-secondary"}
								className={styles.bodyText}
							>
								All of your lab research in one place:
								inventory, experiment, protocols, and more.
							</TypographyV2>
						</div>
						<HighPerformer lightmode={true} />
					</div>
					{/* right */}
					<div className={styles.rightSection}>
						<DemoForm />
					</div>
				</div>
			</div>
			<div className={styles.sectionB}>
				<PartnersV2 />
			</div>
		</div>
	);
}

export const HighPerformer = ({ lightmode = false }) => (
	<div className={styles.highPerformer}>
		<StaticImage
			loading="eager"
			src="../../_assets/winter.png"
			alt="High Performer Winter"
			className={styles.highPerformerImage}
		/>
		<StaticImage
			loading="eager"
			src="../../_assets/summer.png"
			alt="High Performer Summer"
			className={styles.highPerformerImage}
		/>
		<StaticImage
			loading="eager"
			src="../../_assets/fall.png"
			alt="High Performer Fall"
			className={styles.highPerformerImage}
		/>
		<div className={styles.score}>
			<Typography
				variant="HEADER2"
				color={lightmode ? "brand-500" : "ui-01"}
				className={styles.scoreText}
			>
				4.8/5
			</Typography>
			<div className={styles.stars}>
				<Star />
				<Star />
				<Star />
				<Star />
				<HalfStar />
			</div>
		</div>
	</div>
);

const Star = () => {
	return (
		<div className={styles.star}>
			<svg
				width="24"
				height="23"
				viewBox="0 0 24 23"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.5734 1.89058C11.0224 0.508612 12.9776 0.50861 13.4266 1.89058L15.0309 6.82827C15.2318 7.4463 15.8077 7.86475 16.4575 7.86475H21.6493C23.1024 7.86475 23.7066 9.72417 22.531 10.5783L18.3307 13.6299C17.805 14.0119 17.585 14.6889 17.7858 15.307L19.3902 20.2447C19.8392 21.6266 18.2575 22.7758 17.0819 21.9217L12.8817 18.8701C12.3559 18.4881 11.6441 18.4881 11.1183 18.8701L6.91807 21.9217C5.7425 22.7758 4.16078 21.6266 4.60981 20.2447L6.21416 15.307C6.41497 14.689 6.19498 14.0119 5.66925 13.6299L1.469 10.5783C0.293431 9.72417 0.897592 7.86475 2.35068 7.86475H7.54247C8.19231 7.86475 8.76825 7.4463 8.96906 6.82827L10.5734 1.89058Z"
					fill="#FF492C"
				/>
			</svg>
		</div>
	);
};

const HalfStar = () => {
	return (
		<div
			className={styles.star}
			style={{ marginLeft: "-2px", marginTop: "-1px" }}
		>
			<svg
				width="24"
				height="23"
				viewBox="0 0 24 23"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.4723 2.88155L16.5908 8.97638C16.7968 9.56899 17.3499 9.9708 17.9771 9.98358L24.4283 10.115C24.9023 10.1247 25.098 10.727 24.7202 11.0134L19.5784 14.9117C19.0784 15.2907 18.8672 15.9408 19.0488 16.5413L20.9173 22.7174C21.0546 23.1712 20.5423 23.5434 20.1532 23.2726L14.8568 19.587C14.3418 19.2287 13.6582 19.2287 13.1432 19.587L7.84683 23.2726C7.4577 23.5434 6.94538 23.1712 7.08266 22.7174L8.95116 16.5413C9.13283 15.9408 8.92159 15.2907 8.42164 14.9117L3.27979 11.0134C2.90202 10.727 3.09771 10.1247 3.57167 10.115L10.0229 9.98358C10.6501 9.9708 11.2032 9.56899 11.4092 8.97639L13.5277 2.88155C13.6834 2.43377 14.3166 2.43377 14.4723 2.88155Z"
					stroke="#FF492C"
				/>
				<path
					d="M17.5 21V10L14 3L11 10H3.5V11L9.5 16L7.5 23L14 19L17.5 21Z"
					fill="#FF492C"
				/>
			</svg>
		</div>
	);
};

