import { PageLayout } from "@components/index";
import JoinLeadersTestimonials from "@components/JoinLeadersTestimonials/JoinLeadersTestimonials";
import React from "react";
import Faq from "./_components/_Faq/_Faq";
import Features from "./_components/_Features/_Features";
import { FeatureSummary } from "./_components/_FeatureSummary/_FeatureSummary";
import Hero from "./_components/_Hero/_Hero";
import { Industries } from "./_components/_Industries/_Industries";

export default function index() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "why-genemod" }}
			fixedHeaderColor
		>
			<Hero />
			<Features />
			<Industries />
			<FeatureSummary />
			<JoinLeadersTestimonials testimonials={AdsCustomerQuotes} />
			<Faq />
		</PageLayout>
	);
}

const AdsCustomerQuotes = [
	{
		quote: `“My favorite aspect of Genemod is the dynamic inventory storage system which enables you to walk through freezers and boxes digitally.”`,
		author: "Christopher O.",
		authorTitle: "Research Scientist",
	},
	{
		quote: `“Genemod allows our company to manage inventory data end-to-end in one single platform. It is very easy to use and has greatly improved our efficiency.”`,
		author: "Ryan K.",
		authorTitle: "Sr. Scientist",
	},
	{
		quote: `“User-friendly interface, powerful features, and commitment to security and compliance make it a standout choice in the competitive landscape of laboratory software.”`,
		author: "Bhavya V.",
		authorTitle: "Research Scientist",
	},
];

