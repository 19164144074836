import { TypographyV2 } from "@components/index";
import React, { useState } from "react";
import Collapsible from "react-collapsible";
import * as styles from "./_Faq.module.scss";
import { brandedTextForAdsList } from "@components/TypographyV2/TypographyV2";

export default function Faq() {
	return (
		<div className={styles.container}>
			<TypographyV2
				variant={"HEADING_2"}
				weight="REGULAR"
				className={styles.title}
				center
				color="brand-500"
			>
				Frequently asked questions
			</TypographyV2>
			<div className={styles.collapsableContainer}>
				{AdFaqs &&
					AdFaqs.map(({ title, content }, index) => (
						<Collapsible
							tabIndex={0}
							trigger={<FaqTitle title={title} />}
							className={styles.collapsible}
							transitionTime={0.1}
							key={index}
						>
							<FaqContent content={content} />
						</Collapsible>
					))}
			</div>
		</div>
	);
}

const FaqTitle = ({ title }: { title: string }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			className={styles.faqTitleContainer}
			onClick={() => setIsOpen(!isOpen)}
		>
			<TypographyV2
				variant={"BODY_TEXT_MEDIUM"}
				weight={isOpen ? "SEMIBOLD" : "REGULAR"}
				className={styles.title}
				// center
				color={isOpen ? "text-primary" : "text-secondary"}
			>
				{title}
			</TypographyV2>
			{isOpen ? (
				<div
					className=""
					style={{
						width: "12px",
						height: "4px",
						margin: "0 6px",
						backgroundColor: "#B197ED",
					}}
				></div>
			) : (
				<ArrowDown />
			)}
		</div>
	);
};
type ListProps = {
	content:
		| string
		| {
				listTitle: string;
				listContent: {
					listSubTitle: string;
					listItems: string[];
				};
				listFooter?: string;
		  };
};
const FaqContent = ({ content }: ListProps) => {
	return (
		<div className={styles.faqContentContainer}>
			{typeof content == "string" ? (
				<TypographyV2
					variant={"BODY_TEXT_MEDIUM"}
					weight="REGULAR"
					className={styles.title}
					// center
					color="text-tertiary-v3"
				>
					{content}
				</TypographyV2>
			) : (
				<div className={styles.faqContentItemsContainer}>
					<TypographyV2
						variant={"BODY_TEXT_MEDIUM"}
						weight="REGULAR"
						className={styles.title}
						// center
						color="text-tertiary-v3"
					>
						{content.listTitle}
					</TypographyV2>
					{content.listContent && (
						<div className={styles.faqContentList}>
							<TypographyV2
								variant={"BODY_TEXT_MEDIUM"}
								weight="REGULAR"
								className={styles.title}
								// center
								color="text-tertiary-v3"
							>
								{content.listContent.listSubTitle}
							</TypographyV2>

							{content.listContent.listItems &&
								formatListItems(
									content.listContent.listItems
								).map((item, index) => (
									<TypographyV2
										variant={"BODY_TEXT_MEDIUM"}
										weight="REGULAR"
										className={styles.listItemText}
										// center
										color="text-tertiary-v3"
										key={index}
									>
										<span
											style={{
												fontSize: "48px",
												marginTop: "-4px",
											}}
										>
											&#8226;
										</span>{" "}
										{item}
									</TypographyV2>
								))}
							{content.listFooter && (
								<TypographyV2
									variant={"BODY_TEXT_MEDIUM"}
									weight="REGULAR"
									className={styles.title}
									// center
									color="text-tertiary-v3"
								>
									{content.listFooter}
								</TypographyV2>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const ArrowDown = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_307_17326)">
				<path
					d="M6 9L12 15L18 9"
					stroke="#B197ED"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_307_17326">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

const formatListItems = (items: string[]) => {
	return items.map((item) => {
		const colonIndex = item.indexOf(":");
		if (colonIndex !== -1) {
			const brandedText = brandedTextForAdsList`${item.slice(
				0,
				colonIndex + 1
			)}`;
			return (
				<p>
					{brandedText}
					{item.slice(colonIndex + 1)}
				</p>
			);
		} else {
			return <p>{item}</p>;
		}
	});
};

const AdFaqs = [
	{
		title: `What is laboratory information management systems (LIMS software)?`,
		content: `Laboratory Information Management Systems (LIMS) are sophisticated software applications designed to streamline laboratory operations by managing data, automating workflows, and ensuring compliance with regulatory standards.`,
	},
	{
		title: `What is electronic lab notebook (ELN)?`,
		content: `Electronic Lab Notebooks (ELN) digitize the traditional lab notebook, offering an organized, searchable, and secure way to document experiments. ELN software not only captures your research with precision but also enhances collaboration across your team, bringing a new level of innovation and productivity to your lab's workbench.`,
	},
	{
		title: `How can LIMS and ELN benefit my research?`,
		content: `The integration of LIMS and ELN facilitates a seamless research workflow, reducing manual errors, improving data traceability, and saving valuable time. With comprehensive LIMS and ELN solutions, researchers can focus on discovery and innovation, knowing that their data management is in expert hands.`,
	},
	{
		title: `What advantage does Genemod’s LIMS software offer?`,
		content: {
			listTitle: `Genemod's LIMS is engineered to be the central hub for laboratory management, integrating various functionalities such as inventory tracking, electronic lab notebooks, equipment scheduling, order management, and many other scientific research features into a unified platform. This integration facilitates a seamless, efficient workflow, enabling scientists to concentrate on their core research objectives with fewer administrative burdens`,
			listContent: {
				listSubTitle: `Key benefits include:`,
				listItems: [
					"Streamlined operations: Our platform automates and simplifies routine tasks, allowing for more focused research time and less administrative overhead",
					"Budget optimization: The all-in-one nature of Genemod’s LIMS reduces the need for multiple software systems, which can translate into considerable cost savings.",
					"Collaborative environment: Real-time data sharing and management features promote effective team collaboration, regardless of physical location.",
					"Data integrity and compliance: Maintain high data quality with secure, compliant, and traceable data management capabilities.",
					"Operational oversight: Gain insights into your lab's productivity with advanced analytics and reporting, supporting strategic decisions and continuous improvement.",
				],
			},
		},
	},
	{
		title: `Can Genemod’s LIMS software be customized for my specific needs?`,
		content: `The integration of LIMS and ELN facilitates a seamless research workflow, reducing manual errors, improving data traceability, and saving valuable time. With comprehensive LIMS and ELN solutions, researchers can focus on discovery and innovation, knowing that their data management is in expert hands.`,
	},
	{
		title: `What distinguishes Genemod’s LIMS & ELN from other solutions on the market?`,
		content: {
			listTitle: `Genemod's LIMS stands out for their intuitive integration, scalability, and personalized support. While other systems manage data and workflows, Genemod goes further by providing a seamless, user-friendly experience that adapts to your lab's evolving needs.`,
			listContent: {
				listSubTitle: `Distinctive features include:`,
				listItems: [
					"Dynamic LIMS products: Genemod LIMS is a comprehensive platform with virtual freezers, consumable management, order management, electronic lab notebook (ELN), lab equipment scheduler, and many other features for scientific research and lab management.",
					"Intuitive design: Genemod LIMS platform is created with the user experience at the forefront, ensuring that scientists can intuitively navigate and utilize the system with minimal training.",
					"Advanced integration: Genemod offers a truly interconnected system where data flows effortlessly between LIMS and ELN, providing a cohesive ecosystem for all your laboratory data management needs.",
					"Customizable framework: Genemod is highly customizable to align with your specific research processes and goals.",
					"Scalable architecture: Genemod is built to scale with your laboratory's growth, ensuring you have a robust system at every stage of your expansion.",
					"Dedicated support: Our global team of scientists provide unmatched support, bringing their expertise directly to your lab's unique challenges.",
				],
			},
			listFooter: `By choosing Genemod, you're not just selecting software; you're adopting a partnership that propels your lab's efficiency, data management, and innovation potential to new heights.`,
		},
	},
];
