// extracted by mini-css-extract-plugin
export var badge = "_Industries-module--badge--VQ1TK";
export var badgeAndLearnMoreSection = "_Industries-module--badgeAndLearnMoreSection--jpEHj";
export var badges = "_Industries-module--badges--mB47S";
export var badgesMobile = "_Industries-module--badgesMobile--+LOCh";
export var containerV2 = "_Industries-module--containerV2--XILny";
export var content = "_Industries-module--content--v0GQe";
export var contentImageContainer = "_Industries-module--contentImageContainer--oUCYE";
export var firstImage = "_Industries-module--firstImage--FdOyP";
export var imagesCard = "_Industries-module--imagesCard--SNwjc";
export var imagesContainer = "_Industries-module--imagesContainer--mpwci";
export var industries = "_Industries-module--industries--j3YZw";
export var industriesContainer = "_Industries-module--industriesContainer--7HMCE";
export var justTitleAndContent = "_Industries-module--justTitleAndContent--EjIg3";
export var learnMoreButton = "_Industries-module--learnMoreButton--CdViZ";
export var learnMoreButtonContainer = "_Industries-module--learnMoreButtonContainer--pMoIT";
export var learnMoreButtonText = "_Industries-module--learnMoreButtonText--ExEZD";
export var limitWidthOnLargeScreens = "_Industries-module--limitWidthOnLargeScreens--v8KRc";
export var orangeBorder = "_Industries-module--orangeBorder--Y-XZO";
export var secondImage = "_Industries-module--secondImage--fhdHX";
export var tabAndContent = "_Industries-module--tabAndContent--6NLAp";
export var tabElements = "_Industries-module--tabElements--B42AP";
export var tabs = "_Industries-module--tabs--gAcIR";
export var title = "_Industries-module--title--HVun0";
export var titleAndContent = "_Industries-module--titleAndContent--3Llzk";
export var titleAndContentContainer = "_Industries-module--titleAndContentContainer--Z+7iD";