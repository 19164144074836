// extracted by mini-css-extract-plugin
export var body = "_DemoForm-module--body--JStv0";
export var connectText = "_DemoForm-module--connectText--gUnLA";
export var headerText = "_DemoForm-module--headerText--cHcf+";
export var horizontalRule = "_DemoForm-module--horizontalRule--hch6y";
export var input = "_DemoForm-module--input--LzBBF";
export var inputGrid = "_DemoForm-module--inputGrid--lDebW";
export var inputLabel = "_DemoForm-module--inputLabel--99SuF";
export var limitWidthOnLargeScreens = "_DemoForm-module--limitWidthOnLargeScreens--6rrM5";
export var message = "_DemoForm-module--message--UcTRl";
export var privacyLink = "_DemoForm-module--privacyLink--EcTLx";
export var privacyText = "_DemoForm-module--privacyText--kggnX";
export var rightContainer = "_DemoForm-module--rightContainer--tHwdo";
export var submitButton = "_DemoForm-module--submitButton--fI4ry";
export var submitButtonContainer = "_DemoForm-module--submitButtonContainer--Ae4Is";
export var submitSection = "_DemoForm-module--submitSection--SoiHE";