// extracted by mini-css-extract-plugin
export var bodyText = "_Hero-module--bodyText--22epg";
export var container = "_Hero-module--container--Ea5lC";
export var headerText = "_Hero-module--headerText--l1Npg";
export var hero = "_Hero-module--hero--mTME-";
export var heroTextContent = "_Hero-module--heroTextContent--diIcv";
export var highPerformer = "_Hero-module--highPerformer--CBcNC";
export var highPerformerImage = "_Hero-module--highPerformerImage--OqGXR";
export var leftSection = "_Hero-module--leftSection--BM1cg";
export var limitWidthOnLargeScreens = "_Hero-module--limitWidthOnLargeScreens--xjr8c";
export var rightSection = "_Hero-module--rightSection--aKjp0";
export var score = "_Hero-module--score--hYDQW";
export var scoreText = "_Hero-module--scoreText--q4K16";
export var sectionA = "_Hero-module--sectionA--J4nAj";
export var star = "_Hero-module--star--Z0+B-";
export var stars = "_Hero-module--stars--2v0jy";